import './exception.css';
import React, { useEffect, useState } from 'react';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Grid from '@mui/material/Grid';
import ExceptionTable from '../../components/ExceptionTable/ExceptionTable';
import { getExceptions, getJnumbers } from '../../service/thermo-qc-exceptions-service';
import { Button } from '@mui/material';
import ExceptionAPIStatus from '../../components/ExceptionAPIStatus/ExceptionAPIStatus';

const Exception = () => {

    const today = new Date();
    const todayMinus6 = new Date();
    todayMinus6.setDate(todayMinus6.getDate() - 6);

    const [startDate, setStartDate] = useState(todayMinus6);
    const [endDate, setEndDate] = useState(today);
    const [selectedJno, setSelectedJno] = useState('');
    const [jnumberMap, setJnumberMap] = useState([]);
    const [exceptionData, setExceptionData] = useState([]);
    const [errorStatus, setErrorStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(()=> {
        setLoading(true);
        getJnumbers()
        .then((data) => {
            console.log('data########jnum', data.data.Rows);
            setJnumberMap(data.data.Rows);
            setLoading(false);
        }).
        catch((err) => {
            console.log('Error', err);
            setErrorStatus(true);
            setLoading(false);
        });
    }, []);


    function getExceptionsData() {
        setDataLoaded(false);
        setErrorStatus(false);
        setMessage();

        if (selectedJno.length < 8) {
            setMessage('Please select correct J Number');
            return;
        }


        const payload = {
            startDate: startDate.toISOString().split('T')[0] + ' 00:00:00.0000', 
            endDate: endDate.toISOString().split('T')[0] + ' 23:59:59.0000', 
            jNumber : selectedJno
        }

        console.log('payload', payload);
        setLoading(true);
        getExceptions(payload)
        .then((data) => {
            console.log('data########exc', data);
            setExceptionData(data.data);

            data?.data.length > 0 ? setMessage() : setMessage('No Exceptions Found');
            setLoading(false);
            setDataLoaded(true);
        }).
        catch((err) => {
            console.log('Error', err);
            setErrorStatus(true);
            setLoading(false);
        });
    }

    return (
        <div>
            <div className='exception'>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Start Date"
                                value={startDate}
                                onChange={(val) => {
                                    setStartDate(val)
                                }}
                                renderInput={(params) => <TextField {...params} size="small" />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={(val) => {
                                    setEndDate(val)
                                }}
                                renderInput={(params) => <TextField {...params} size="small" />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={jnumberMap}
                            getOptionLabel={option => option + ''}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option} value={option}>
                                        {option}
                                    </li>
                                );
                            }}
                            //style={{ width: 300 }}
                            onChange={(newValue) => {
                                console.log('@@###', newValue);
                                setSelectedJno(newValue.target.attributes.value.nodeValue);
                            }}
                            renderInput={(params) => <TextField {...params} label="JNumber" size="small" />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button 
                            variant="contained" 
                            style={{backgroundColor: "#727272" }}
                            onClick={getExceptionsData}
                        >Get Data</Button>
                    </Grid>
                </Grid>

                    
                
            </div>
            
            <ExceptionAPIStatus loading={loading} error={errorStatus} message={message}></ExceptionAPIStatus>
            { dataLoaded && !message && <ExceptionTable data={exceptionData}/>}
        </div>
    );
}

export default Exception;