import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Dashboard from '../pages/dashboard/dashboard';
import Exception from '../pages/exception/exception';
import "../App.css";
import AssayReport from '../pages/assay/assay';

const RouterInfo = (props) => {

    const signOut = () => {
        console.log("in !");
        props.signOut();
    }

    return (
        <Router>
            <div className="App">
                <div className="App-header">
                    <img src={require("../assets/Ortho-logo-white.png")} alt="logo" />
                    <div className="header-links">
                        <Link to="/">Home </Link>
                        <Link to="/exception"> Exception</Link>
                        <Link to="/assay_report"> Assay Report</Link>


                        <button onClick={() => signOut()}>
                            Logout
                        </button>
                    </div>
                </div>
                <Routes>
                    <Route path="/" exact element={<Dashboard />}></Route>
                    <Route path="/exception" element={<Exception />}></Route>
                    <Route path="/assay_report" element={<AssayReport />}></Route>
                </Routes>
            </div>
        </Router>
    )
}

export default RouterInfo;
