import './assay.css';
import React, { useEffect, useState } from 'react';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Grid from '@mui/material/Grid';
import AssayReportTable from '../../components/AssayReportTable/AssayReportTable';
import { 
        getExceptions,
        getJnumbers,
        getAssayReport,
        getAssayShortNames,
        getControlNames,
        getLotNumbers,
        getLotExpDates,
        getLevels,
        getShelfLifes,
        getGenExpDates,
} from '../../service/thermo-qc-exceptions-service';
import { Button, useStepContext } from '@mui/material';
import ExceptionAPIStatus from '../../components/ExceptionAPIStatus/ExceptionAPIStatus';

const AssayReport = () => {

    const today = new Date();
    const todayMinus6 = new Date();
    todayMinus6.setDate(todayMinus6.getDate() - 6);

    const [startDate, setStartDate] = useState(todayMinus6);
    const [endDate, setEndDate] = useState(today);
    const [selectedJno, setSelectedJno] = useState('');
    // const [jnumberMap, setJnumberMap] = useState([]);
    const [assayQCReportData, setAssayQCReportData] = useState([]);
    const [errorStatus, setErrorStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [dataLoaded, setDataLoaded] = useState(false);


    const [ assayShortNameList, setAssayShortNameList ] = useState([])
    const [ controlNameList, setControlNameList ] = useState([])
    const [ lotNumberList, setLotNumberList ] = useState([])
    const [ lotExpDateList, setLotExtDateList ] = useState([])
    const [ levelList, setLevelList ] = useState([])
    const [ shelfLifeList, setShelfLifeList ] = useState([])
    const [ genExpDateList, setGenExpDateList ] = useState([])

    const [ selectedData, setSelectedData ] = useState({
        assay_short_name:'',
        control_name:'',
        lot:'',
        lot_exp_dt:'',
        level:'',
        // shelfLift:'',
        gen_exp_dt:''
    })

    const updateSelectedData = (keyName,value) => {
        // assay_short_name=Ferr&control_name=Liquichek Tumor Marker Controls&lot=94921&lot_exp_dt=2023-12-31 00:00:00.000&level=1&gen_exp_dt=2022-03-15 00:00:00.000

        console.log('{keyName,value} === ',keyName,{keyName,value})
        let tempSelectedData = {...selectedData}
        tempSelectedData[keyName] = value ? value : ''
        setSelectedData(tempSelectedData)
    }

    const getRequiredData = async () => {
        setLoading(true);
        try{
            let assayShortNames = await getAssayShortNames()
            setAssayShortNameList(assayShortNames.data)
        }catch(e){
            console.log('get assayShortNames Error',e)
            setAssayShortNameList([])
        }

        try{
            let controlNames = await getControlNames()
            setControlNameList(controlNames.data)
        }catch(e){
            console.log('get Control Names Error',e)
            setControlNameList([])
        }

        try{
            let lotNumbers = await getLotNumbers()
            setLotNumberList(lotNumbers.data)
        }catch(e){
            console.log('get Lot Numbers Error',e)
            setLotNumberList([])
        }

        try{
            let lotExpDates = await getLotExpDates()
            setLotExtDateList(lotExpDates.data)
        }catch(e){
            console.log('get Lot Exp Dates Error',e)
            setLotExtDateList([])
        }

        try{
            let levels = await getLevels()
            setLevelList(levels.data)
        }catch(e){
            console.log('get Lot Exp Dates Error',e)
            setLevelList([])
        }

        // try{
        //     let shelfLifes = await getShelfLifes()
        //     setShelfLifeList(shelfLifes.data)
        // }catch(e){
        //     console.log('get shelf lifes Error',e)
        //     setShelfLifeList([])
        // }

        try{
            let genExpDates = await getGenExpDates()
            setGenExpDateList(genExpDates.data)
        }catch(e){
            console.log('get gen exp dates Error',e)
            setGenExpDateList([])
        }

        setLoading(false);
    }

    useEffect(()=> {
        getRequiredData()
        // console.log('sadfasdfsadfasfdsadf',)
        // getAssayShortNames()
        // .then(resp => setAssayShortNameList(resp.data))
        // .then(data => console.log('get assay short name',data))
    }, []);


    function getAssayReportData() {
        let {assay_short_name,lot} = selectedData
        console.log('let {assay_short_name,lot} = selectedData',{assay_short_name,lot})
        if(!assay_short_name)
            assay_short_name = ''
        if(!lot)
            lot = ''
        if(assay_short_name.trim() === '' && lot.trim() === ''){
            setMessage("To generate the report please choose either assay_short_name or lot number")
            return
        }else{
            setDataLoaded(false);
            setErrorStatus(false);
            setMessage();

            const payload = selectedData

            console.log('payload', payload);
            setLoading(true);
            getAssayReport(payload)
            .then((data) => {
                console.log('data########assay', data);
                setAssayQCReportData(data.data);

                data?.data.length > 0 ? setMessage() : setMessage('No Data Found');
                setLoading(false);
                setDataLoaded(true);
            }).
            catch((err) => {
                console.log('Error', err);
                setErrorStatus(true);
                setLoading(false);
            });
        }
    }

    return (
        <div>
            <div className='exception'>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={assayShortNameList.map( e => e.assay_short_nm)}
                            getOptionLabel={option => option + ''}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option} value={option}>
                                        {option}
                                    </li>
                                );
                            }}
                            //style={{ width: 300 }}
                            onChange={(newValue) => {
                                console.log('@@### short name', newValue);
                                updateSelectedData('assay_short_name',newValue?.target?.attributes?.value?.nodeValue ? newValue.target.attributes.value.nodeValue : '')
                                // setSelectedJno(newValue.target.attributes.value.nodeValue ? newValue.target.attributes.value.nodeValue : '');
                            }}
                            renderInput={(params) => <TextField {...params} label="Assay Short Name" size="small" />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                    <Autocomplete
                            disablePortal
                            id="combo-box-demo2"
                            options={controlNameList.map( e => e.control_name)}
                            getOptionLabel={option => option + ''}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option} value={option}>
                                        {option}
                                    </li>
                                );
                            }}
                            //style={{ width: 300 }}
                            onChange={(newValue) => {
                                console.log('@@###', newValue);
                                updateSelectedData('control_name',newValue?.target?.attributes?.value?.nodeValue ? newValue.target.attributes.value.nodeValue : '')
                                // setSelectedJno(newValue.target.attributes.value.nodeValue ? newValue.target.attributes.value.nodeValue : '');
                            }}
                            renderInput={(params) => <TextField {...params} label="Control Name" size="small" />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo3"
                            options={lotNumberList.map( e => e.lot_num)}
                            getOptionLabel={option => option + ''}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option} value={option}>
                                        {option}
                                    </li>
                                );
                            }}
                            //style={{ width: 300 }}
                            onChange={(newValue) => {
                                console.log('@@###', newValue);
                                updateSelectedData('lot',newValue?.target?.attributes?.value?.nodeValue ? newValue.target.attributes.value.nodeValue : '')
                                // setSelectedJno(newValue.target.attributes.value.nodeValue ? newValue.target.attributes.value.nodeValue : '');
                            }}
                            renderInput={(params) => <TextField {...params} label="Lot #" size="small" />}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo4"
                            options={lotExpDateList.map( e => e.lot_exp_date)}
                            getOptionLabel={option => option + ''}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option} value={option}>
                                        {option}
                                    </li>
                                );
                            }}
                            //style={{ width: 300 }}
                            onChange={(newValue) => {
                                console.log('@@###', newValue);
                                updateSelectedData('lot_exp_dt',newValue?.target?.attributes?.value?.nodeValue ? newValue.target.attributes.value.nodeValue : '')
                                // setSelectedJno(newValue.target.attributes.value.nodeValue ? newValue.target.attributes.value.nodeValue : '');
                            }}
                            renderInput={(params) => <TextField {...params} label="Lot Exp Date" size="small" />}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo5"
                            options={levelList.map( e => e.lvl)}
                            getOptionLabel={option => option + ''}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option} value={option}>
                                        {option}
                                    </li>
                                );
                            }}
                            //style={{ width: 300 }}
                            onChange={(newValue) => {
                                console.log('@@###', newValue);
                                updateSelectedData('level',newValue?.target?.attributes?.value?.nodeValue ? newValue.target.attributes.value.nodeValue : '')
                                // setSelectedJno(newValue.target.attributes.value.nodeValue ? newValue.target.attributes.value.nodeValue : '');
                            }}
                            renderInput={(params) => <TextField {...params} label="Level" size="small" />}
                        />
                    </Grid>

                    {/* <Grid item xs={3}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo6"
                            options={shelfLifeList}
                            getOptionLabel={option => option + ''}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option} value={option}>
                                        {option}
                                    </li>
                                );
                            }}
                            //style={{ width: 300 }}
                            onChange={(newValue) => {
                                console.log('@@###', newValue);
                                setSelectedJno(newValue.target.attributes.value.nodeValue ? newValue.target.attributes.value.nodeValue : '');
                            }}
                            renderInput={(params) => <TextField {...params} label="Shelf Life" size="small" />}
                        />
                    </Grid> */}

                    <Grid item xs={3}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo7"
                            options={genExpDateList.map( e => e.gen_exp_date)}
                            getOptionLabel={option => option + ''}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option} value={option}>
                                        {option}
                                    </li>
                                );
                            }}
                            //style={{ width: 300 }}
                            onChange={(newValue) => {
                                console.log('@@###', newValue);
                                updateSelectedData('gen_exp_dt',newValue?.target?.attributes?.value?.nodeValue ? newValue.target.attributes.value.nodeValue : '')
                                // setSelectedJno(newValue.target.attributes.value.nodeValue ? newValue.target.attributes.value.nodeValue : '');
                            }}
                            renderInput={(params) => <TextField {...params} label="Gen Exp Date" size="small" />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button 
                            variant="contained" 
                            style={{backgroundColor: "#727272" }}
                            onClick={getAssayReportData}
                        >Get Data</Button>
                    </Grid>
                </Grid>

                    
                
            </div>
            
            <ExceptionAPIStatus loading={loading} error={errorStatus} message={message}></ExceptionAPIStatus>
            { dataLoaded && !message && <AssayReportTable data={assayQCReportData}/>}
        </div>
    );
}

export default AssayReport;