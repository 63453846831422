import { callApi } from '../utilities/utilities'

function getJnumbers() {
  // return callApi('get-list-of-jnumbers', {}, { 'Content-Type': 'application/json' }, 'GET');
  return callApi('get-j-numbers', {}, { 'Content-Type': 'application/json' }, 'GET','exception');
}

function getExceptions({ startDate, endDate, jNumber }) {
  //s_date=2022-02-05 00:00:00.0000
  //e_date=
  //j_no=76000214
  return callApi("get-lot-exception-report?s_date=" + startDate + "&e_date=" + endDate + "&j_no=" + jNumber, {}, { 'Content-Type': 'application/json' }, 'GET','exception');
}

function getAssayReport(payload) {
  let urlString = `get-qc-assay-report?`
  for(let i in payload){
    if(payload[i]?.trim() === '' || !payload[i])
      delete(payload[i])
  }
  let paramsString = Object
                        .keys(payload)
                        .map( k => k + '=' + payload[k])
                        .join('&')
  urlString = urlString + paramsString
  return callApi(urlString,{}, { 'Content-Type': 'application/json' }, 'GET','assay')
}


function getAssayShortNames(){
  return callApi('get-assay-short-name', {}, { 'Content-Type': 'application/json' }, 'GET','assay');
}

function getControlNames(){
  return callApi('get-control-name', {}, { 'Content-Type': 'application/json' }, 'GET','assay');
}

function getLotNumbers(){
  return callApi('get-lot', {}, { 'Content-Type': 'application/json' }, 'GET','assay');
}

function getLotExpDates(){
  return callApi('get-lot-exp-date', {}, { 'Content-Type': 'application/json' }, 'GET','assay');
}

function getLevels(){
  return callApi('get-level', {}, { 'Content-Type': 'application/json' }, 'GET','assay');
}

function getShelfLifes(){
  return callApi('get-list-of-shelf-lifes', {}, { 'Content-Type': 'application/json' }, 'GET','assay');
}

function getGenExpDates(){
  return callApi('get-gen-expdt', {}, { 'Content-Type': 'application/json' }, 'GET','assay');
}
export { 
  getJnumbers,
  getExceptions,
  getAssayReport,
  getAssayShortNames,
  getControlNames,
  getLotNumbers,
  getLotExpDates,
  getLevels,
  getShelfLifes,
  getGenExpDates,
}
