import { callApi } from '../utilities/utilities'

function getInitialLogs(params) {
    const { year, month, date } = params;
    console.log(params);
    return callApi('get-logs-timestamps?year=' + year + '&month=' + month + '&date=' + date , {}, { 'Content-Type': 'application/json' }, 'GET','dashboard');
}

function getCSVData(params) {
    const { path, fileName } = params;
    return callApi("get-csv-data?path=" + path + "&" + "filename=" + fileName, {}, { 'Content-Type': 'application/json' }, 'GET','dashboard');
}


export { getInitialLogs, getCSVData }