import * as React from 'react';
import './AssayReportTable.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const AssayReportTable = (props) => {
    console.log('assay report table props',props)
    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className='tableHeader'>Lot</TableCell>
                            <TableCell className='tableHeader'>Control Name</TableCell>
                            <TableCell className='tableHeader'>Assay Short Name</TableCell>
                            <TableCell className='tableHeader'>Level</TableCell>
                            <TableCell className='tableHeader'>Assay Gen</TableCell>
                            <TableCell className='tableHeader'>Body Fluid Name</TableCell>
                            <TableCell className='tableHeader'>Thermo Body Fluid Name</TableCell>
                            <TableCell className='tableHeader'>Mean</TableCell>
                            <TableCell className='tableHeader'>ROM Low</TableCell>
                            <TableCell className='tableHeader'>ROM High</TableCell>
                            <TableCell className='tableHeader'>St Dev</TableCell>
                            <TableCell className='tableHeader'>Thermo Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {props.data.map((d, index) => (
                        <TableRow key={index}>
                            <TableCell>{d.pv_lot}</TableCell>
                            <TableCell>{d.control_name}</TableCell>
                            <TableCell>{d.assay_short_nm}</TableCell>
                            <TableCell>{d.lvl}</TableCell>
                            <TableCell>{d.assay_gen}</TableCell>
                            <TableCell>{d.bodyfluid_nm}</TableCell>
                            <TableCell>{d.thermo_bodyfluid_nm}</TableCell>
                            <TableCell>{d.mean}</TableCell>
                            <TableCell>{d.rom_low}</TableCell>
                            <TableCell>{d.rom_high}</TableCell>
                            <TableCell>{d.std_dev}</TableCell>
                            <TableCell>{d.thermo_status}</TableCell>
                            
                            {/* <TableCell>{d.model}</TableCell>
                            <TableCell>{d.Serial_Number}</TableCell>
                            <TableCell>{d.assay_type}</TableCell>
                            <TableCell>{d.lassay_number}</TableCell>
                            <TableCell>{d.assay_nm}</TableCell>
                            <TableCell>{d.bodyfluid_nm}</TableCell>
                            <TableCell>{d.wcontrollotnumber}</TableCell>
                            <TableCell>{d.SubLot}</TableCell>
                            <TableCell>{d.Error_Description}</TableCell> */}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
        </>
    )
}

export default AssayReportTable;
