import * as Constants from "../constants";
import Cookies from "js-cookie";
import axios from 'axios';
import { Auth } from 'aws-amplify'

const APIGatewayMap = {
  dashboard: Constants.CONFIG_API_URL_DASHBOARD,
  exception: Constants.CONFIG_API_URL,
  assay: Constants.CONFIG_API_URL_ASSAY
}

const JWT = require("jsonwebtoken");

/**
 * Will return True if the auth_token cookie is present and not expired.
 */

// async function getAccessToken() {
//   let accessToken;
//   await Auth.currentSession().then(res => {
//     accessToken = res.getAccessToken().getJwtToken();
//   });

//   return accessToken;
// }
export async function hasValidToken() {
  // const token = Cookies.get(Constants.AUTH_TOKEN_COOKIE_NAME);
  let accessToken;
  let jwt;
  let isValid;
  await Auth.currentSession().then(res => {
    accessToken = res.getAccessToken();
    jwt = accessToken.getJwtToken();

    console.log('this is that token thermo',jwt)
    isValid = res.isValid;
  })

  return isValid();
}

export function isEmployee(idToken) {
  if (idToken !== undefined) {
    const decodedToken = JWT.decode(idToken);
    if (decodedToken["custom:UserType"] === "E") return true;
  }
  return false;
}

export function isUnauthorized() {
  const cookie = Cookies.get(Constants.INVALID_TOKEN_COOKIE_NAME);
  return cookie !== undefined;
}

/*
 * Refreshes the app page.
 */
export function refreshPage() {
  window.location.href = Constants.APP_URL;
}

/*
 * Redirects the page to the Ortho login screen.
 */
export function redirectToLogin() {
  console.log('redirecting to login')
  // axios.get(Constants.LOGIN_URL);
  window.location.href = Constants.LOGIN_URL;
  // window.location.reload(Constants.LOGIN_URL)
}

export function redirectToLogout() {
  window.location.href = Constants.LOGOUT_URL;
}

export function redirectToInvalidateToken() {
  Cookies.remove(Constants.AUTH_TOKEN_COOKIE_NAME);
  Cookies.set(Constants.INVALID_TOKEN_COOKIE_NAME, false, {
    expires: 60 / 86400, // 86400 = 1 day in seconds
  });
  refreshPage();
}

export function redirectToHome() {
  window.location.href = Constants.HOME_URL;
}

export function redirectToCognitoLogout() {
  // Cookies.remove(Constants.AUTH_TOKEN_COOKIE_NAME);
  // axios.request(Constants.COGNITO_LOGOUT_URL);
  window.location.href = Constants.COGNITO_LOGOUT_URL;
}

/**
 * Makes an authenticated call to the api endpoint specified.
 *
 * Returns: An XMLHttpRequest on success. Otherwise undefined (will redirect).
 */
export async function callApi(endpoint, data, headers = null, method = "POST",APIKey = '') {
  // console.log('qc assay payload',data)
  let accessToken;
  let jwt;
  let cognitoSession;
  await Auth.currentSession().then(res => {
    accessToken = res.getAccessToken();
    jwt = res.getIdToken().getJwtToken();

    cognitoSession = res;
    // isValid = res.isValid.bind(res);
    //console.log(accessToken, jwt, cognitoSession.isValid());
  });
  // console.log("jwt :-------", jwt)
  if (cognitoSession.isValid()) {
    console.log('assay url', Constants.CONFIG_API_URL_ASSAY)
    console.log('other url',Constants.CONFIG_API_URL)
    // const url = isAssay ? Constants.CONFIG_API_URL_ASSAY + endpoint : Constants.CONFIG_API_URL + endpoint;
    // const url = `https://hjzq725ncf.execute-api.us-east-1.amazonaws.com/Dev/`+endpoint
    const url = APIGatewayMap[APIKey] + endpoint
    headers.authorization = "Bearer " + jwt;
    // if(!isAssay){
    //   headers.authorization = "Bearer " + jwt;
    // }
    console.log('exception headers', headers)
    return axios.request({ method, data, url, headers });
  } else {
    Auth.federatedSignIn();
    return "";
  }
}

export default {
  hasValidToken,
  refreshPage,
  redirectToLogin,
  redirectToLogout,
  callApi,
  isEmployee,
  isUnauthorized,
  redirectToInvalidateToken,
  redirectToHome,
  redirectToCognitoLogout
};
