import React from "react";
import "./ThermoQCForm.css";
import { getInitialLogs, getCSVData } from '../../service/thermo-qc-logs-service';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const defaultState = {
  thermoQCLogs: {},
  selectedFileName: "",
  fileNames: [],
  tableData: {},
  selectedCategory: "",
  selectedDate: new Date(),
  categoryReset: '-1'
};

class ConfigForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
    this.updateFileNames = this.updateFileNames.bind(this);
    this.defaultFileNames = this.defaultFileNames.bind(this);
    this.setSelectedDate = this.setSelectedDate.bind(this);
    this.resetCategory = this.resetCategory.bind(this);
  }

  componentDidMount() {
    //fetch initial log files details
    this.getLogs();
  }

  getLogs() {
    const year = this.state.selectedDate.getFullYear();
    const month = this.state.selectedDate.getMonth() + 1;
    const date = this.state.selectedDate.getDate();

    getInitialLogs({year, month, date }).then(resp => {
      console.log('initial logs === ',resp.data);
      this.setState({ thermoQCLogs: resp.data })
      this.resetCategory("-1");
    })
  }

  setSelectedDate(selectedDate) {
    this.setState({ selectedDate }, () => {
      this.getLogs();
    });
  }

  resetCategory(value) {
    this.setState({categoryReset: value});
    this.setState({ selectedFileName: "", fileNames: [] });
    this.props.onStatusUpdate("");
    this.props.setTableData({});
  }

  defaultFileNames(event) {
    let selectedCategory = event.target.value;
    this.resetCategory(selectedCategory);
    this.setState({ selectedCategory });
    //empty existing table data before call
    this.props.setTableData({});
    if (selectedCategory === "-1" || selectedCategory === "") {
      this.setState({ selectedFileName: "", fileNames: [] });
      this.props.onStatusUpdate("");
      return;
    }
    console.log('this.state.thermoQCLogs[selectedCategory]',this.state.thermoQCLogs,selectedCategory)
    const slectedCatLogFiles = this.state.thermoQCLogs[selectedCategory];
    if (slectedCatLogFiles.length === 0) {
      this.setState({ selectedFileName: "", fileNames: [] });
      this.props.onStatusUpdate("No Files found for given category.");
      return;
    }

    const { lastModified, path, fileName } = slectedCatLogFiles[0];
    console.log('selected log files === ',slectedCatLogFiles,'asdf',slectedCatLogFiles[0])
    //setting status to loading before call out
    this.props.onStatusUpdate("loading...");
    this.props.loading(true);
    //todo service callout to get default timestamp data
    getCSVData({ path, fileName }).then(resp => {
      this.props.loading(false)
      const tableData = resp.data;
      this.setState({ tableData });
      this.props.setTableData(tableData);
      this.props.setFileName(fileName);
    })
    const fileNames = slectedCatLogFiles.map(fileDetails => (fileDetails.fileName));
    this.setState({ fileNames, selectedFileName: lastModified });
    this.props.onStatusUpdate("")
  }

  updateFileNames(event) {
    // let selectedCategory = event.target.value;
    let index = event.target.value;
    // empty existing table data before call
    this.props.setTableData({});
    console.log('watch this');
    console.log(event.target.value, this.state.thermoQCLogs[this.state.selectedCategory][event.target.value]);

    if (this.state.selectedCategory === "-1" || this.state.selectedCategory === "") {
      this.setState({ selectedFileName: "", fileNames: [] });
      return;
    }
    const slectedCatLogFiles = this.state.thermoQCLogs[this.state.selectedCategory]
    if (slectedCatLogFiles.length === 0) {
      this.setState({ selectedFileName: "", fileNames: [] })
      return;
    }
    this.props.onStatusUpdate("loading...")
    const { lastModified, path, fileName } = slectedCatLogFiles[index];
    //todo service callout to get default timestamp data
    this.props.loading(true);
    getCSVData({ path, fileName }).then(resp => {
      this.props.loading(false)
      const tableData = resp.data;
      this.setState({ tableData });
      this.props.setTableData(tableData);
      this.props.setFileName(fileName);
    })
    const fileNames = slectedCatLogFiles.map(fileDetails => (fileDetails.fileName));
    this.setState({ fileNames, selectedFileName: lastModified });
  }



  render() {
    return (
      <div className="ConfigForm">
        <div className="form-field">
        <label>Date</label>
          <DatePicker selected={this.state.selectedDate} onChange={(date) => this.setSelectedDate(date)} />
          <label>Category</label>
          <select value={this.state.categoryReset} id="category" disabled={Object.keys(this.state.thermoQCLogs).length === 0} onChange={this.defaultFileNames}>
            <option value="-1">Please select a category</option>
            { Object.keys(this.state.thermoQCLogs).length === 0 ?
              "" :
              Object.keys(this.state.thermoQCLogs).map((category, ind) =>
                (<option id={category} value={category} key={category + ind}>{category}</option>))
            }
          </select>
        </div>
        <div className="form-field">
          <label>File Name</label>
          <select
            id="select-filename"
            disabled={this.state.selectedFileName === ""}
            onChange={this.updateFileNames}
          >
            {this.state.fileNames?.map((fileName, ind) => (
              <option key={fileName + ":::" + ind} value={ind}>
                {fileName}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

export default ConfigForm;
