import React, { useState, useEffect } from "react";
import "./ThermoQCContent.css";
import ConfigTable from "../ThermoQCTable/ThermoQCTable";

function ConfigContent({ statusMessage, tableData, fileName }) {
  // States
  const [filter, setFilter] = useState("");
  const [filterIndex, setFilterIndex] = useState(0);
  const [filterValue, setFilterValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const [columnsDropdown, setColumnsDropdown] = useState(false);
  const headers = tableData.Header;
  const [defaultHeaders, setDefaultHeaders] = useState(headers?.reduce((acc, val, ind) => {
    if (["Status", "ErrorCode", "ErrorMessage", "assayname", "control_lot_number"].includes(val)) acc.push(ind);
    return acc;
  }, []))
  const disabledHeaders = headers?.reduce((acc, val, ind) => {
    if (["Status", "ErrorCode", "ErrorMessage", "assayname", "control_lot_number"].includes(val)) acc.push(ind);
    return acc;
  }, []);
  // const [selectedHeaders, setSelectedHeaders] = useState(defaultHeaders ? defaultHeaders : []);

  useEffect(() => {
    console.log(tableData, "from effect", defaultHeaders)
  }, [tableData, defaultHeaders]);
  function selectFilter(event) {
    setFilterIndex(event.target.selectedOptions[0].dataset.index)
    setFilter(event.target.value);
    if (event.target.value === "") setFilteredData(tableData.Rows);
  }

  function downloadConfigFile() {
    const csvData = headers.toString() + "\n" + tableData.Rows.reduce((acc, row) => acc + row.toString() + "\n", "");
    const contentType = "text/csv;charset=utf-8;";
    const csvBlob = new Blob([csvData], { type: contentType });
    const filename = fileName + ".csv";
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(csvBlob, filename);
    } else {
      const a = document.createElement("a");
      a.download = filename;
      a.href = window.URL.createObjectURL(csvBlob);
      a.dataset.downloadurl = [contentType, a.download, a.href].join(":");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  if (headers && headers.length > 0 && defaultHeaders?.length > 0) {
    return (
      <div className="ConfigContent">
        <div className="table-filters">
          <div>
            <div className="multiSelect-box" onClick={() => setColumnsDropdown(!columnsDropdown)}>
              <span>Optional Columns</span>
              <div className="caret"></div>
            </div>
            <div className={"multiselect-checkbox" + " " + (columnsDropdown ? "display-block" : "display-none")}>
              {(headers.map((val, ind) => {
                let data = { value: val, isSelected: false, disabled: false }
                if (defaultHeaders.includes(ind)) {
                  data.isSelected = true;
                  if (disabledHeaders.includes(ind)) data.disabled = true;
                }
                return data;
              })).map((attr, index) => (
                <div className="checkbox" key={index}>
                  <input type="checkbox" id={attr.value} name="columns" onClick={(event) => {
                    const checkedIndex = Number(event.target.value);
                    if (!defaultHeaders.includes(checkedIndex)) {
                      setDefaultHeaders([...defaultHeaders, checkedIndex])
                    } else {
                      setDefaultHeaders([...defaultHeaders.filter(val => !(val === checkedIndex))])
                    }

                  }} value={index} defaultChecked={attr.isSelected} disabled={attr.disabled} />
                  <label htmlFor={attr.value}>{attr.value}</label>
                </div>
              ))}
            </div>
          </div>


          <div>
            <label>Filter By</label>
            <select
              id="select-attribute"
              onChange={selectFilter}
              disabled={headers !== undefined && headers.length <= 0}
            >
              {(defaultHeaders ? headers.map((val, ind) => ({ value: val, index: ind }))
                .filter((val, index) => defaultHeaders.includes(index)) : headers).map((attr, index) => (
                  <option data-index={attr.index} key={attr.value} value={attr.value}>
                    {attr.value}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <label>Filter Value</label>
            <input
              type="text"
              id="input-filter"
              placeholder="Enter filter value"
              disabled={headers !== undefined && headers.length <= 0}
              value={filterValue}
              onChange={(event) => setFilterValue(event.target.value)}
            ></input>
          </div>
          <button
            onClick={() => {
              const element = document.getElementById("input-filter")
              const filterValue1 = element.value;
              console.log(filterValue1, element)
              const filteredData = tableData.Rows.filter(val => {
                return val[filterIndex]?.includes(filterValue);
              });
              if (filteredData.length === 0) {
                setFiltered(true);
              } else {
                setFiltered(false);
              }
              setFilteredData(filteredData);
              console.log("filtering", filteredData)
            }
            }
            disabled={headers !== undefined && headers.length <= 0}
          >
            Search
            </button>
        </div>
        <ConfigTable
          selectedData={filteredData.length > 0 ? filteredData : (filtered ? [] : tableData.Rows)}
          attributes={headers}
          selectedHeaders={defaultHeaders}
          className="table-container"
          statusMessage={statusMessage}
        ></ConfigTable>
        <div className="table-bottom">
          <div className="info">
            <p>Results: {filteredData.length > 0 ? filteredData.length : tableData.Rows.length}</p>
            <p>Columns: {headers.length}</p>
          </div>
          <button type="button" onClick={downloadConfigFile}>
            Download All
          </button>
        </div>
      </div >
    );
  } else {
    return <b style={{ color: "red" }}>{statusMessage}</b>;
  }
}

export default ConfigContent;
