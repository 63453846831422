import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useState } from "react";
import "../../App.css";
import ConfigForm from '../../components/ThermoQCFrom/ThermoQCForm';
import ConfigContent from '../../components/ThermoQCContent/ThermoQCContent';

const Dashboard = () => {
    const [statusMessage, setStatusMessage] = useState("");
    const [tableData, setTableData] = useState({});
    const [fileName, setFileName] = useState("");
    const [isLoading, setLoading] = useState(false);
    function updateStatus(statusMessage) {
        setStatusMessage(statusMessage);
    }


    return (
        <div className="App-content">
          <div className="form-container">
            <ConfigForm
              setTableData={setTableData}
              onStatusUpdate={updateStatus}
              setFileName={setFileName}
              loading={setLoading}
            />
          </div>
          <div className="table-container">
            {
              Object.keys(tableData).includes("Header") ? <ConfigContent
                statusMessage={statusMessage}
                tableData={tableData}
                fileName={fileName}
              /> : (!isLoading ? <div className="table-message-box">
                <b style={{ color: "red" }}>
                  {statusMessage ? statusMessage : "Please select required criteria."}
                </b>
              </div> : "")
            }

          </div>
          {
            isLoading ?
              (<div className="loader">
                <img width="40px" src={require("../../assets/ajax-loader.gif")} />
              </div>)
              : ""
          }

        </div>
    );
}

export default Dashboard;