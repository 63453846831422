import React, { useState } from "react";
import { Card } from "@mui/material";
import './ExceptionAPIStatus.css'

const ExceptionAPIStatus = (props) => {
    if (props?.loading || props?.error || props.message) {
        return (
            <Card variant="outlined">
                { props.loading && <div className="loading">Loading</div> }
                { props.error && <div className="error">Error while loading data</div> }
                { props.message && <div className="status">{props.message}</div> }
            </Card>
        )    
    } else {
        return (<></>)
    }
    
}

export default ExceptionAPIStatus;