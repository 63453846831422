import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: 'us-east-1:5cf54d99-cbdd-42b4-9a34-32293e80bc9a',

        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_COGNITO_REGION,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
        // Required only if it's different from Amazon Cognito Region
        // identityPoolRegion: 'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,

        oauth: {
            domain: process.env.REACT_APP_COGNITO_DOMAIN,//your_cognito_domain
            scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: process.env.REACT_APP_HOME_URL,//redirect url after sign in
            redirectSignOut: process.env.REACT_APP_LOGOUT_URL,//redirect url after sign out
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
});

// You can get the current config object
export const currentConfig = Auth.configure();