import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useState, useEffect } from "react";
import "./App.css";
import * as Constants from "./constants";
import { currentConfig } from './utilities/amplify-config'
import Amplify, { Auth } from 'aws-amplify';
import RouterInfo from './utilities/router';

Amplify.configure(currentConfig);

function App() {
  const [userSession, setUserSession] = useState(false);
  useEffect(() => {
    // Update the document title using the browser API
    Auth.currentAuthenticatedUser()
      .then(user => console.log({ user }))
      .catch(() => {
        console.log("Not signed in redirecting to sign in");
        Auth.federatedSignIn();
      });
    const getSession = async () => {
      await Auth.currentSession().then(userSession => {

        setUserSession(userSession);
      });
    }
    getSession();
  }, []);

  if (userSession && userSession?.isValid()) {

    const cognitoSignOut = () => {
      Auth.signOut();
    }

    return (
      <RouterInfo signOut={cognitoSignOut} ></RouterInfo>
    );
  } else {
    /* If no valid token exists, the page redirects so you can obtain one */
    return (
      <>
        You will be automatically redirected or{" "}
        <a href="#" onClick={Auth.federatedSignIn}>
          click here to login
        </a>
        .
      </>
    );
  }
}

export default App;
