import * as React from 'react';
import './ExceptionTable.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const ExceptionTable = (props) => {
    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className='tableHeader'>Model</TableCell>
                            <TableCell className='tableHeader'>J Number</TableCell>
                            <TableCell className='tableHeader'>Assay Type</TableCell>
                            <TableCell className='tableHeader'>Assay Number</TableCell>
                            <TableCell className='tableHeader'>Assay Name</TableCell>
                            <TableCell className='tableHeader'>Bodyfuild</TableCell>
                            <TableCell className='tableHeader'>WControl Lot Number</TableCell>
                            <TableCell className='tableHeader'>Sublot</TableCell>
                            <TableCell className='tableHeader'>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {props.data.map((d, index) => (
                        <TableRow key={index}>
                            <TableCell>{d.model}</TableCell>
                            <TableCell>{d.Serial_Number}</TableCell>
                            <TableCell>{d.assay_type}</TableCell>
                            <TableCell>{d.lassay_number}</TableCell>
                            <TableCell>{d.assay_nm}</TableCell>
                            <TableCell>{d.bodyfluid_nm}</TableCell>
                            <TableCell>{d.wcontrollotnumber}</TableCell>
                            <TableCell>{d.SubLot}</TableCell>
                            <TableCell>{d.Error_Description}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
        </>
    )
}

export default ExceptionTable;
